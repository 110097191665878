/** @format */

// export const users = [
//     {
//       id: 1,
//       name: 'John Doe',
//       email: 'john.doe@example.com',
//     },
//     {
//       id: 2,
//       name: 'Jane Smith',
//       email: 'jane.smith@example.com',
//     },
//     {
//       id: 3,
//       name: 'Robert Johnson',
//       email: 'robert.johnson@example.com',
//     },
//     {
//       id: 4,
//       name: 'Emily Davis',
//       email: 'emily.davis@example.com',
//     },
//     {
//       id: 5,
//       name: 'Michael Wilson',
//       email: 'michael.wilson@example.com',
//     },
//     {
//       id: 6,
//       name: 'Olivia Thompson',
//       email: 'olivia.thompson@example.com',
//     },
//     {
//       id: 7,
//       name: 'William Anderson',
//       email: 'william.anderson@example.com',
//     },
//     {
//       id: 8,
//       name: 'Sophia Brown',
//       email: 'sophia.brown@example.com',
//     },
//     {
//       id: 9,
//       name: 'James Lee',
//       email: 'james.lee@example.com',
//     },
//     {
//       id: 10,
//       name: 'Emma Clark',
//       email: 'emma.clark@example.com',
//     },
//     {
//       id: 11,
//       name: 'Benjamin Martinez',
//       email: 'benjamin.martinez@example.com',
//     },
//     {
//       id: 12,
//       name: 'Ava Adams',
//       email: 'ava.adams@example.com',
//     },
//     {
//       id: 13,
//       name: 'Daniel Walker',
//       email: 'daniel.walker@example.com',
//     },
//     {
//       id: 14,
//       name: 'Mia Hill',
//       email: 'mia.hill@example.com',
//     },
//     {
//       id: 15,
//       name: 'Ethan Wright',
//       email: 'ethan.wright@example.com',
//     },
//   ];

export const users = [
  {
    id: 1,
    name: "Jason Mike",
    status: "Delivered",
  },
  {
    id: 2,
    name: "Jonathan Revita",
    status: "Not Delivered",
  },

  {
    id: 3,
    name: "Diana  Johnson ",
    status: "Not Delivered",
  },
  {
    id: 4,
    name: "Mia George",
    status: "Not Delivered",
  },
  {
    id: 5,
    name: "Charlotte Jean",
    status: "Delivered",
  },
  {
    id: 6,
    name: "Sylvia Rich",
    status: "Not Delivered",
  },
  {
    id: 7,
    name: "Elaine Cox ",
    status: "Not Delivered",
  },
  {
    id: 8,
    name: "Susan Watson ",
    status: "Not Delivered",
  },
  {
    id: 9,
    name: "James Robin",
    status: "Delivered",
  },
  {
    id: 10,
    name: "Pat John",
    status: "Delivered",
  },

  {
    id: 11,
    name: "Sohia Mary",
    status: "Delivered",
  },
  {
    id: 12,
    name: "John Gosen",
    status: "Delivered",
  },
  {
    id: 13,
    name: "Kathleen Barrett ",
    status: "Not Delivered",
  },
  {
    id: 14,
    name: "Doreen Gonzalez",
    status: "Delivered",
  },
  {
    id: 15,
    name: "Richard Gosen ",
    status: "Not Delivered",
  },
  {
    id: 16,
    name: "John Gosen",
    status: "Delivered",
  },
  {
    id: 17,
    name: "Linda Connelly Young",
    status: "Not Delivered",
  },
  {
    id: 18,
    name: "Joan Davis",
    status: "Delivered",
  },
  {
    id: 19,
    name: "Connie Merritt",
    status: "Not Delivered",
  },
  {
    id: 20,
    name: " John Koranda",
    status: "Delivered",
  },
  {
    id: 21,
    name: "Trish Reeder",
    status: "Delivered",
  },
  {
    id: 22,
    name: "David Thompson",
    status: "Not Delivered",
  },
  {
    id: 23,
    name: " Nancy Hoenie",
    status: "Not Delivered",
  },

  {
    id: 24,
    name: "Judy Flores",
    status: "Not Delivered",
  },
  {
    id: 25,
    name: "Gary Davis",
    status: "Not Delivered",
  },
  {
    id: 26,
    name: "Bradley Johnson ",
    status: "Delivered",
  },
  {
    id: 27,
    name: "Angie Morgan ",
    status: "Not Delivered",
  },
  { id: 28, name: "Pål Tangen ", status: " Delivered" },
  { id: 29, name: "Petter Sörlle  ", status: " Delivered" },
  { id: 30, name: "Krystyna Ziobro-Wojarski   ", status: "Not Delivered" },
];
