import React, { useEffect } from 'react';
import {
  FaDatabase,
  FaAsterisk,
  FaAccusoft,
  FaFacebook,
  FaTwitter,
  FaInstagram,
} from 'react-icons/fa';
import './Hero.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Advert from '../asset/advert.png';

const Hero = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='hero'>
      <div className='container'>
        <div className='content'>
          <div className='col-1'>
            <h1
              data-aos='fade-right'
              data-aos-duration='500'
              data-aos-easing='ease-in-sine'
            >
              <span className='primary-color'>Convenience</span> Comes to ICL
            </h1>

            <p
              data-aos='zoom-in'
              data-aos-duration='500'
              data-aos-easing='ease-in-sine'
            >
              The programme focused on the causes of poverty and deprivation and
              bringing about a long-term difference to the lives of some of the
              most disadvantaged people in the world. The programme outcomes
              were closely aligned to the Millennium Development Goals, and
              focussed on primary education, health, access to natural
              resources, human rights and sustainable livelihoods.,
            </p>
            <div className='used-by'>
              <p>USED BY</p>
              <div className='icons'>
                <i>
                  <FaDatabase />
                  Finance
                </i>
                <i>
                  <FaAsterisk />
                  Taxes
                </i>
                <i>
                  <FaAccusoft />
                  Diplomacy
                </i>
              </div>
            </div>
          </div>
          <div className='col-2'>
            <img
              src={Advert}
              alt=''
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                paddingTop: 50,
                borderRadius: '50%',
                position: 'relative',
              }}
            />
          </div>
          <div id='scroll-container'>
            <div id='scroll-text'>
              <p
                style={{
                  fontSize: 20,
                  color: 'snow',
                  textAlign: 'left',
                  fontWeight: '700',
                }}
              >
                International Country Lottery
              </p>
            </div>
          </div>
        </div>
        <div id='scroll-contain' style={{ textAlign: 'center' }}>
          <div id='scroll-tex'>
            <p
              style={{
                fontSize: 20,
                color: 'snow',
                textAlign: 'left',
                fontWeight: '700',
              }}
            >
              International Country Lottery
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
