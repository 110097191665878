import React, { useState } from "react";
import "./UserTable.css";

const UserTable = ({ users }) => {
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * itemsPerPage;
  const currentUsers = users.slice(offset, offset + itemsPerPage);

  const pageCount = Math.ceil(users.length / itemsPerPage);

  const handlePageChange = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <div className="user-table-container">
      <div>
        <h2 id="list_header">List of Winners</h2>
        <p id="text-page">
          Note: If your Winning money hasnt been delivered to you it means you
          havent paid your necessary fee , immediately you contact the Agent for
          your necessary fee payment, your money will be delivered as soon as
          possible with your Approval code.
        </p>
      </div>

      <table className="user-table">
        <thead>
          <tr>
            <th id="id-header">ID</th>
            <th id="email-header ">Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.status}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination-container">
        <div className="page-info">
          Page {currentPage + 1} of {pageCount}
        </div>
        <div className="pagination-buttons">
          <button
            className="pagination-button"
            onClick={() => handlePageChange({ selected: currentPage - 1 })}
            disabled={currentPage === 0}
          >
            Previous
          </button>
          <button
            className="pagination-button"
            onClick={() => handlePageChange({ selected: currentPage + 1 })}
            disabled={currentPage === pageCount - 1}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
