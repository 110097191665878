import React from 'react';
import Navbar from '../componet/Navbar';
import Footer from '../componet/Footer';
import Faqs from '../componet/Faq';
const Faqslink = () => {
  return (
    <div>
      <div>
        <Navbar />
        <Faqs />
        <Footer />
      </div>
    </div>
  );
};

export default Faqslink;
