import React from 'react';
import './Faqs.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import advert2 from '../asset/advert2.png';
import { useEffect } from 'react';
const Faq = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='section-faq'>
      <div className='container'>
        <div data-aos='fade-right' data-aos-duration='1500' className='col-1'>
          <h3>Frequently asked questions</h3>
          <p>Can't find the answer you're looking for?</p>
          <p>
            Reach out to our
            <a href='mailto:sw8109762gmail.com'>
              <span> customer support </span>
            </a>
            team.
          </p>
          <img
            src={advert2}
            alt=''
            style={{ width: '100%', objectFit: 'contain', borderRadius: 8 }}
          />
        </div>
        <div data-aos='zoom-out' data-aos-duration='1500' className='col-2'>
          <div className='faq'>
            <h5>What is International Country Lottery (ICL)</h5>
            <p>
              The ICL is funding projects in the arts, sport, heritage, charity,
              voluntary, health, education and environmental sectors. Supporting
              positive change in the community that are having to adapt to the
              increasing challenges of COVID-19. Alot of challenges during the
              Covid 19 pandemic and also the people affected by the climatic
              change recently and most important due to the economic challenges
              happening . The People and Communities programme is designed to
              support you to work with local people to bring about positive
              changes in your community. A community could be a geographical
              area or a community of interest.
            </p>
          </div>
          <div className='faq'>
            <h5>Is this legit</h5>
            <p>
              The programme is 100% legit which as the support of the
              International Organisations .The involvement and sponsorship of
              United Nation , International Monetary Fund, Bitcoin companies,
              Walmart store , walgreen store , Cocoa cola , Pepsi etc has played
              a vital role to help the people in which a lot of people have
              benefit accross the state actors
            </p>
          </div>
          {/* <div className='faq'>
            <h5>How do you make holy water?</h5>
            <p>
              You biol the hell out of it. Lorem ipsum dolor sit amet
              consectetur.
            </p>
          </div>
          <div className='faq'>
            <h5>How do you make holy water?</h5>
            <p>
              You biol the hell out of it. Lorem ipsum dolor sit amet
              consectetur.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Faq;
