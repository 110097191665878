import React from 'react';
import Form from '../form/Form';
import Navbar from '../componet/Navbar';
import Footer from '../componet/Footer';

const Forms = () => {
  return (
    <div>
      <Navbar />
      <Form />
      <Footer />
    </div>
  );
};

export default Forms;
