import React from "react";
import Navbar from "../componet/Navbar";
import Footer from "../componet/Footer";
import UserTable from "../componet/UserTable";
import { users } from "../data";

const Tableswin = () => {
  return (
    <div>
      <Navbar />
      <UserTable users={users} />
      <Footer />
    </div>
  );
};

export default Tableswin;
