import React from 'react';
import { FaDatabase } from 'react-icons/fa';
import './Testify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

const Testify = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='testimonial'>
      <div data-aos='fade-up' data-aos-duration='2000' className='container'>
        <div className='outline'>
          <div className='content'>
            <i>
              <FaDatabase />
              International Diplomacy
            </i>
            <div className='scroll_container'>
              <div className='scroll_text' style={{ marginTop: 30 }}>
                Picking winners among the many young once seeking money is a way
                of social and economic development in the society, even for
                those that need business establishment ,debt and bills. Indeed,
                Apply for International Country lottery now instead. Your chance
                of winning is likely to be higher.Those who have prospered and
                profited from life's lottery have a moral obligation to share
                their good fortune.
              </div>
            </div>
            <div className='name' style={{ marginTop: 20 }}>
              <p>Applicant review</p>
              <p>People's money</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testify;
