import person1 from '../asset/person1.jpeg';
export const dataLottery = [
  {
    id: 1,
    title: 'Elizabeth Mai',
    images:
      'https://images.pexels.com/photos/13419244/pexels-photo-13419244.jpeg?auto=compress&cs=tinysrgb&w=600',
    pages:
      'I was so exicted when i got my lottery money ,due to the pandemic i lost my home and job ,i dont know how to survive untill when my friend Susan told me about the International Country Lottery . i was so happy to get $1,000,000 for my bills',
  },
  {
    id: 2,
    title: 'Jason Maze',
    images:
      'https://images.pexels.com/photos/7477750/pexels-photo-7477750.jpeg?auto=compress&cs=tinysrgb&w=600',
    pages:
      "International Country Lottery is the best having a great debt at hand wasn't so good to me ,due to my application with the lottery i got $2,500,000 to clear of my debt and home bills ",
  },
  {
    id: 3,
    title: 'Belle Jack',
    images:
      'https://images.pexels.com/photos/4453710/pexels-photo-4453710.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load',
    pages:
      '  It was a heavy lifting help for me , had a medical bills couldnt payoff and i was sick thanks to International Country lottery for the support and help they delivered my money the next day when i applied ',
  },
];
