import React, { useState } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../asset/logos.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
const Navbar = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };
  return (
    <header>
      <nav className='navbar'>
        <div data-aos='zoom-up' data-oas-duration='1500' className='logo'>
          <Link to='/'>
            <img
              src={logo}
              alt=''
              style={{ width: '100%', objectFit: 'cover' }}
            />
            {/* <h3>International Country Lottery</h3>
            <p>People money</p> */}
          </Link>
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li
            data-aos='fade-left'
            data-aos-duration='1000'
            data-aos-easing='ease-in-sine'
            className='nav-item'
          >
            <Link to='/' className='nav-link'>
              Home
            </Link>
          </li>
          <li
            data-aos='fade-left'
            data-aos-duration='1500'
            data-aos-easing='ease-in-sine'
            className='nav-item'
          >
            <Link to='/about' className='nav-link'>
              About Us
            </Link>
          </li>
          <li
            data-aos='fade-left'
            data-aos-duration='2000'
            data-aos-easing='ease-in-sine'
            className='nav-item'
          >
            <Link to='/faq' className='nav-link'>
              FAQ
            </Link>
          </li>
          <li
            data-aos='fade-left'
            data-aos-duration='2500'
            data-aos-easing='ease-in-sine'
            className='nav-item'
          >
            <Link to='/contact' className='nav-link'>
              Contact
            </Link>
          </li>
          <li
            data-aos='fade-left'
            data-aos-duration='2500'
            data-aos-easing='ease-in-sine'
            className='nav-item'
          >
            <Link to='/winners' className='nav-link'>
              Winners
            </Link>
          </li>
          <li
            className='nav-item'
            // data-aos='fade-left'
            // data-aos-duration='2500'
            // data-aos-easing='ease-in-sine'
            style={{ boxShadow: '5px 5px #fff' }}
          >
            <Link to='/forms' className='nav-link'>
              Register
            </Link>
          </li>
        </ul>
        <div className='hamburger' onClick={handleClick}>
          {click ? (
            <FaTimes size={20} style={{ color: '#ffffff' }} />
          ) : (
            <FaBars size={20} style={{ color: '#ffffff' }} />
          )}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
