import React from 'react';
import Hero from './Hero';
import './Home.css';
import Navbar from './Navbar';
import About from './About';
import Testify from './Testify';
import Faq from './Faq';
import Contact from './Contact';
import Footer from './Footer';
import ScrollSlider from './Slider';
import Tables from '../form/BasicTable';
import UserTable from './UserTable';
import { users } from '../data';


const Home = () => {
  return (
    <>
      <div>
        <Navbar />
        <Hero />
        <About />
        <ScrollSlider />
        {/* <CardSlider/> */}
        <UserTable users={users} />
        <Testify />
        <Faq />
        <Contact />
        {/* <Tables /> */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
