import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import './Contact.css';
const Contact = () => {
  return (
    <div className='contact'>
      <div className='container'>
        <div className='col-1'>
          <div className='content'>
            <div>
              <h2>Get in touch</h2>
            </div>
            <p>
              Kindly contact one of the Agent below through email or phone
              contact if you have any complain or for more information
            </p>
          </div>
          <div className='address'>
            <p>745 Evergreen Terrace</p>
            <p>Springfiled </p>
          </div>
          <div className='icons'>
            <FaPhone style={{ marginRight: '1rem' }} />

            <p>+1 (505) 896-0004  </p>
          </div>
          <div className='icons'>
            <FaEnvelope style={{ marginRight: '1rem' }} />
            <a
              href='mailto:Michaelburger209@gmail.com'
              style={{ marginRight: 10 }}
            >
              <span>Michaelburger209@gmail.com </span>
            </a>
            <br />
          </div>
          <div className='icons'>
            <FaEnvelope style={{ marginRight: '1rem' }} />
            <a href='mailto:sw8109762gmail.com'>
              <span>sw810976@gmail.com</span>
            </a>
          </div>
          <div className='careers'>
            <p>
              Looking for careers? <span>View all job openings.</span>
            </p>
          </div>
        </div>
      </div>
      <div className='col-2'>
        <form action=''>
          <input type='text' placeholder='Full name' />
          <input type='email' placeholder='Email' />
          <input type='Phone' placeholder='Phone' />
          <textarea
            name='Message'
            placeholder='Message'
            cols={30}
            rows='10'
          ></textarea>

          <div className='checkbox'>
            <p>
              By checking this box ,you agrree to the
              <span> Privacy Policy </span>
              Cookie Policy
            </p>
          </div>
          <button>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
