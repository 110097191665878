import React from 'react';
import Navbar from '../componet/Navbar';
import Abt from '../componet/About';
import Footer from '../componet/Footer';

const Aboutus = () => {
  return (
    <div>
      <Navbar />
      <Abt />
      <Footer />
    </div>
  );
};

export default Aboutus;
