import React from "react";
import person1 from "../asset/person1.jpeg";
import "./Slider.css";
import Slider from "react-slick";
import { dataLottery } from "./Data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import person2 from "../asset/person2.jpeg";

import { Avatar, Card } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIosOutlined } from "@material-ui/icons";

const Sliders = () => {
  const first = person1;
  const second = person2;

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 1000,

    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true,
          fade: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Prev = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowBackIos style={{ color: "blue", fontSize: "30px" }} />
      </div>
    );
  };

  const Next = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowForwardIosOutlined style={{ color: "blue", fontSize: "30px" }} />
      </div>
    );
  };
  return (
    <Card className="App" elevation={0}>
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        <h1>Testimonials</h1>
      </div>
      <Slider {...settings} prevArrow={<Prev />} nextArrow={<Next />}>
        {dataLottery.map((item) => (
          <div className="card" elevation={0}>
            <div className="card-top">
              <Avatar
                style={{
                  borderRadius: "50%",
                  width: 120,
                  height: 120,
                  borderColor: "gray",
                }}
                src={item.images}
                alt={item.title}
              />
            </div>
            <div className="card-bottom">
              <p className="text">{item.pages}</p>
              <h3>{item.title}</h3>
            </div>
          </div>
        ))}
      </Slider>
    </Card>
  );
};

export default Sliders;
